const initRegionsLoader = async () => {
    const regionsSelect = document.querySelector('#order_bill_address_attributes_state_id');
    const countrySelect = document.querySelector('#order_bill_address_attributes_country_id');
    if (!regionsSelect || !regionsSelect.dataset.api || !countrySelect) {
        return;
    }
    const apiPath = regionsSelect.dataset.api;
    try {
        const res = await fetch(apiPath, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });
        const json = await res.json();
        const regions = json.states;
        countrySelect.addEventListener('change', () => {
            const countryId = parseInt(countrySelect.value);
            const currentRegions = regions.filter((r) => r.country_id === countryId).sort((a, b) => {
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });
            const template = document.createElement('div');
            currentRegions.forEach((region) => {
                const option = document.createElement('option');
                option.value = region.id;
                option.textContent = region.name;
                template.append(option);
            });
            regionsSelect.innerHTML = template.innerHTML;
        });
    } catch (e) {
        console.error(e);
    }
};

document.addEventListener('turbolinks:load', initRegionsLoader);