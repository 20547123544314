require("../jg/burger");
require("../jg/menu");
require("./solidus.js.erb");
require("./product-tabs");
require("./reviews");
require("./cart");
require("./slider");
require("./price");
require("./accordion");
require("./order");
require("./coupon");
require("./checkout");
require("./properties");
