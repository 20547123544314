const initCountButtons = () => {
    const plusBtn = document.querySelector('#cart-count-plus');
    const minusBtn = document.querySelector('#cart-count-minus');
    const countInput = document.querySelector('#quantity');

    if (!plusBtn && !minusBtn && !countInput) {
        return;
    }

    const max = parseInt(countInput.getAttribute('max'));
    const min = parseInt(countInput.getAttribute('min'));

    plusBtn.addEventListener('click', (evt) => {
        evt.preventDefault();
        let inputValue = parseInt(countInput.value);
        if (isNaN(max)) {
            plusBtn.removeAttribute('disabled');
            minusBtn.removeAttribute('disabled');
            countInput.value = ++inputValue;
            return;
        }
        if (max === inputValue) {
            return;
        }
        if (max > inputValue) {
            countInput.value = ++inputValue;
            if (max - inputValue === 0) {
                plusBtn.setAttribute('disabled', '1');
            }
            if (min < inputValue) {
                minusBtn.removeAttribute('disabled');
            }
        }
    });

    minusBtn.addEventListener('click', (evt) => {
        evt.preventDefault();
        let inputValue = parseInt(countInput.value);
        if (isNaN(min) && inputValue > 1) {
            countInput.value = --inputValue;
            return;
        } else if (isNaN(min) && inputValue === 1) {
            minusBtn.setAttribute('disabled', '1');
            return;
        }

        if (min < inputValue) {
            countInput.value = --inputValue;
            if (inputValue - min === 0) {
                minusBtn.setAttribute('disabled', '1')
            }
            if (max > inputValue) {
                plusBtn.removeAttribute('disabled');
            }
        }
    });
};

document.addEventListener('turbolinks:load', initCountButtons);