window.Solidus = {};

Solidus.mountedAt = () => {
  return '/store/';
};

Solidus.pathFor = (path) => {
  const hostname = window.location.hostname;
  const protocol = window.location.protocol;
  const port = window.location.port;
  const locationOrigin = protocol + '//' + hostname + (port ? ':' + port : '');
  return locationOrigin + Solidus.mountedAt() + path;
};

Solidus.routes = {
  states_search: Solidus.pathFor('api/states'),
  order_apply_coupon_code: (order_number) => Solidus.pathFor('api/orders/' + order_number + '/coupon_codes'),
  order_destroy_coupon_code: (order_number, coupon_id) => Solidus.pathFor('api/orders/' + order_number + '/coupon_codes/' + coupon_id),
};
