const initBurger = () => {
    const burgerBtn = document.querySelector('#burger');
    const menu = document.querySelector('.header__nav');
    if (burgerBtn && menu) {
        burgerBtn.addEventListener('click', () => {
            burgerBtn.classList.toggle('burger--open');
            menu.classList.toggle('header__nav--visible');
        });
    }
};

document.addEventListener('turbolinks:load', initBurger);