import {MOBILE_SCREEN_WIDTH} from "./constants";

const initAccordions = async () => {
    const accordionBtns = document.querySelectorAll('[data-accordiontrigger]');
    const accordions = document.querySelectorAll('[data-accordion]');

    const invertArrow = (arrow) => {
        arrow.classList.add('arrow--down');
        arrow.classList.add('arrow--inverted');
        arrow.classList.remove('arrow--up');
    };

    const normalizeArrow = (arrow) => {
        arrow.classList.remove('arrow--down');
        arrow.classList.remove('arrow--inverted');
        arrow.classList.add('arrow--up');
    };

    accordionBtns.forEach((btn) => {
        btn.addEventListener('click', (evt) => {
            evt.preventDefault();
            if (window.innerWidth > MOBILE_SCREEN_WIDTH) {
                return;
            }
            const accordionsArray = Array.from(accordions);
            const accordionId = btn.dataset.accordiontrigger;

            if (!accordionId) {
                return;
            }

            const accordion = accordionsArray.find((acc) => acc.dataset.accordion === accordionId);
            if (!accordion) {
                return;
            }

            const arrow = btn.querySelector('.arrow');

            if (accordion.classList.contains('d-none')) {
                accordion.classList.remove('d-none');
                if (arrow) {
                    normalizeArrow(arrow);
                }
            } else {
                accordion.classList.add('d-none');
                if (arrow) {
                    invertArrow(arrow);
                }
            }
        });
    });
};

document.addEventListener('turbolinks:load', initAccordions);