const initTabs = () => {
    const tabButtons = document.querySelectorAll('[data-tabtrigger]');
    const tabs = Array.from(document.querySelectorAll('[data-tab]'));

    tabButtons.forEach((btn) => {
        btn.addEventListener('click', (evt) => {
            evt.preventDefault();
            const tabName = btn.dataset.tabtrigger;
            const tab = tabs.find((t) => t.dataset.tab === tabName);
            if (tab && !tab.classList.contains('product__tab--active')) {
                tabs.forEach((t) => t.classList.remove('product__tab--active'));
                tabButtons.forEach((b) => b.classList.remove('product__tab-btn--active'));
                tab.classList.add('product__tab--active');
                btn.classList.add('product__tab-btn--active');
            }
        });
    });
};

document.addEventListener('turbolinks:load', initTabs);