const initOrderCountButtons = () => {
    const plusBtns = document.querySelectorAll('.order__count-btn--plus');
    const minusBtns = document.querySelectorAll('.order__count-btn--minus');

    plusBtns.forEach((plusBtn) => {
        plusBtn.addEventListener('click', (evt) => {
            evt.preventDefault();
            const dataInput = plusBtn.dataset.input;
            if (!dataInput) {
                return;
            }
            const countInput = document.querySelector(`[input="${dataInput}"]`);
            const minusBtn = document.querySelector(`.order__count-btn--minus[data-input="${dataInput}"]`);
            if (!countInput && !minusBtn) {
                return;
            }
            const max = parseInt(countInput.getAttribute('max'));
            const min = parseInt(countInput.getAttribute('min'));
            let inputValue = parseInt(countInput.value);

            if (isNaN(max)) {
                plusBtn.removeAttribute('disabled');
                minusBtn.removeAttribute('disabled');
                countInput.value = ++inputValue;
                return;
            }
            if (max === inputValue) {
                return;
            }
            if (max > inputValue) {
                countInput.value = ++inputValue;
                if (max - inputValue === 0) {
                    plusBtn.setAttribute('disabled', '1');
                }
                if (min < inputValue) {
                    minusBtn.removeAttribute('disabled');
                }
            }
        });
    });

    minusBtns.forEach((minusBtn) => {
        minusBtn.addEventListener('click', (evt) => {
            evt.preventDefault();
            const dataInput = minusBtn.dataset.input;
            if (!dataInput) {
                return;
            }
            const countInput = document.querySelector(`[input="${dataInput}"]`);
            const plusBtn = document.querySelector(`.order__count-btn--plus[data-input="${dataInput}"]`);
            if (!countInput && !plusBtn) {
                return;
            }
            const max = parseInt(countInput.getAttribute('max'));
            const min = parseInt(countInput.getAttribute('min'));
            let inputValue = parseInt(countInput.value);

            if (isNaN(min) && inputValue > 1) {
                countInput.value = --inputValue;
                return;
            } else if (isNaN(min) && inputValue === 1) {
                minusBtn.setAttribute('disabled', '1');
                return;
            }

            if (min < inputValue) {
                countInput.value = --inputValue;
                if (inputValue - min === 0) {
                    minusBtn.setAttribute('disabled', '1')
                }
                if (max > inputValue) {
                    plusBtn.removeAttribute('disabled');
                }
            }
        });
    })
};

const initRemoveButtons = () => {
    const removeButtons = document.querySelectorAll('.order__remove-btn');
    const updateButton = document.querySelector('#update-button');

    if (!updateButton) {
        return;
    }

    removeButtons.forEach((btn) => {
        btn.addEventListener('click', (evt) => {
            evt.preventDefault();
            const dataInput = btn.dataset.input;
            if (!dataInput) {
                return;
            }
            const countInput = document.querySelector(`[input="${dataInput}"]`);
            if (!countInput) {
                return;
            }
            countInput.value = 0;
            updateButton.click();
        });
    })
};

document.addEventListener('turbolinks:load', initOrderCountButtons);
document.addEventListener('turbolinks:load', initRemoveButtons);