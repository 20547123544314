const initCreateReviewBtns = () => {
    const createReviewBtns = document.querySelectorAll('.js-create-review');
    const tabBtn = document.querySelector('[data-tabtrigger="reviews"]');
    const reviewsForm = document.querySelector('.reviews__form');
    const nameInput = document.querySelector('#name');

    createReviewBtns.forEach((btn) => {
        btn.addEventListener('click', (evt) => {
            evt.preventDefault();
            if (tabBtn) {
                tabBtn.click();
            }
            createReviewBtns.forEach((b) => {
                b.classList.add('hide');
            })
            if (reviewsForm) {
                reviewsForm.classList.add('reviews__form--active');
            }
            if (nameInput) {
                nameInput.focus();
            }
        });
    });
};

const initValidityForm = () => {
    const form = document.querySelector('[action="/store/products/solidus-mug/ajax_reviews"]');

    if (!form) {
        return;
    }

    const submitBtn = form.querySelector('.reviews__submit-review');
    const ratingField = form.querySelector('[name="review[rating]"]');

    if (!submitBtn && !ratingField) {
        return;
    }

    submitBtn.addEventListener('click', (evt) => {
        const dataForm = new FormData(form);
        const rating = dataForm.get('review[rating]');
        if (rating === null) {
            ratingField.setCustomValidity('Пожалуйста, укажите рейтинг');
        } else {
            ratingField.setCustomValidity('');
        }
    });
};

document.addEventListener('turbolinks:load', initCreateReviewBtns);
document.addEventListener('turbolinks:load', initValidityForm);