const initCouponManagement = () => {
    initCouponCreation();
    initCouponDeletion();
};

const initCouponCreation = () => {
    const applyCouponButton = document.querySelector('#order-coupon-apply');
    const statusEl = document.querySelector('#order-coupon-status');
    const codeInput = document.querySelector('#order-coupon-code-input');

    if (!applyCouponButton || !statusEl || !codeInput) {
        return;
    }

    console.log("init coupon creation form");

    const orderNumber = applyCouponButton.dataset.orderNumber;
    const path = Solidus.routes.order_apply_coupon_code(orderNumber);

    codeInput.addEventListener("keypress", (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            applyCouponButton.click();
        }
    });

    applyCouponButton.addEventListener("click", async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const orderToken = applyCouponButton.dataset.orderGuestToken;
        const couponCode = codeInput.value.trim();

        if (couponCode === "") {
            return
        }

        const data = {
            order_token: orderToken,
            coupon_code: couponCode,
        };

        const res = await fetch(path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + ''
            },
            body: JSON.stringify(data),
        });
        const json = await res.json();

        if (res.ok) {
            location.reload();
        } else {
            console.error(json);
            statusEl.innerHTML = JSON.stringify(json["error"]);
            statusEl.classList.remove('d-none')
        }
    });
}

const initCouponDeletion = () => {
    const destroyCouponButton = document.querySelector('#order-coupon-destroy');

    if (!destroyCouponButton) {
        return;
    }

    console.log("init coupon deletion buttons");

    destroyCouponButton.addEventListener("click", async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const orderNumber = destroyCouponButton.dataset.orderNumber;
        const orderToken = destroyCouponButton.dataset.orderGuestToken;
        const couponCode = destroyCouponButton.dataset.couponCode;

        const path = Solidus.routes.order_destroy_coupon_code(orderNumber, couponCode);

        const data = {
            order_token: orderToken,
        };

        const res = await fetch(path, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + ''
            },
            body: JSON.stringify(data),
        });

        const json = await res.json();

        if (res.ok) {
            location.reload();
        } else {
            console.error(json);
        }
    });
};

document.addEventListener('turbolinks:load', initCouponManagement);