const bindMenuEvents = () => {
    const header = document.querySelector('.header');
    const products = document.querySelector('.nav__products');
    const productsButton = document.querySelector('#products_nav');
    const visibleClass = 'nav__products--visible';

    if (!header || !products || !productsButton) {
        return;
    }

    products.classList.remove(visibleClass);

    // this fires both click and touch
    productsButton.addEventListener('click', (e) => {
        e.preventDefault();
        products.classList.toggle(visibleClass);
    });

    if ('ontouchstart' in window) {
        // this closes menu on touch outside the header
        header.addEventListener('mouseleave', () => {
            products.classList.remove(visibleClass);
        });
    } else {
        let mouseLeaveTimeout;

        header.addEventListener('mouseover', () => {
            if (mouseLeaveTimeout) {
                clearTimeout(mouseLeaveTimeout);
            }
        });

        // add a timeout to close menu 500ms after mouse leaves the header
        header.addEventListener('mouseleave', () => {
            mouseLeaveTimeout = setTimeout(() => {
                products.classList.remove(visibleClass);
            }, 500);
        });
    }
};

document.addEventListener('turbolinks:load', bindMenuEvents);
