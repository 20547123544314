const initPriceUpdater = () => {
    const price = document.querySelector('#product-price');
    const variants = document.querySelectorAll('[name="variant_id"]');

    if (!price && !variants.length) {
        return;
    }

    const updateVariantPrice = () => {
        variants.forEach((variant) => {
            if (variant.checked) {
                price.textContent = variant.dataset.price;
            }
        });
    };

    variants.forEach((variant) => {
        variant.addEventListener('change', updateVariantPrice);
    });

    updateVariantPrice();
};

document.addEventListener('turbolinks:load', initPriceUpdater);
