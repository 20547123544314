import Swiper, {Navigation, Scrollbar, Thumbs} from 'swiper';
import {debounce} from "./helpers";
import {MOBILE_SCREEN_WIDTH} from "./constants";

Swiper.use([Scrollbar, Navigation, Thumbs]);

const initMainPageSwiper = () => {
    new Swiper(".products__slider", {
        scrollbar: {
            el: ".products__scrollbar",
            draggable: true
        },
        grabCursor: true
    });
};

const initProductPageSwiper = () => {
    const productImagesTemplate = document.querySelector('#product-images');
    const productImagesContainer = document.querySelector('.product__images');
    const overlay = document.querySelector('.product__images-overlay');
    const closeFullScreenBtn = document.querySelector('.product__image-close-fullscreen');

    if (!productImagesTemplate || !productImagesTemplate.content || !productImagesContainer || !overlay || !closeFullScreenBtn) {
        return;
    }

    const productImages = JSON.parse(productImagesTemplate.content.textContent);
    if (!productImages || !productImages.length) {
        return;
    }

    const variants = document.querySelectorAll('[name="variant_id"]');

    const getCurrentVariant = () => {
        let currentVariant = null;
        variants.forEach((variant) => {
            if (variant.checked) {
                currentVariant = parseInt(variant.value);
            }
        });
        return currentVariant;
    };

    const getDirection = () =>  window.innerWidth > MOBILE_SCREEN_WIDTH ? 'vertical' : 'horizontal';

    const galleryThumbs = new Swiper('.product-gallery-thumbs', {
        spaceBetween: 16,
        slidesPerView: 4,
        freeMode: true,
        allowTouchMove: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        direction: getDirection(),
    });
    const galleryMain = new Swiper('.product-gallery', {
        spaceBetween: 10,
        navigation: {
            nextEl: '.product__image-next',
            prevEl: '.product__image-prev',
        },
        thumbs: {
            swiper: galleryThumbs
        },
        direction: getDirection(),
        allowTouchMove: window.innerWidth <= MOBILE_SCREEN_WIDTH
    });

    const appendSlides = () => {
        const currentVariant = getCurrentVariant();
        const currentSlides = productImages.filter((img) => {
            return !currentVariant || !img.id || img.id === currentVariant;
        });
        const galleryThumbsSlides = [];
        const galleryMainSlides = [];
        galleryThumbs.removeAllSlides();
        galleryMain.removeAllSlides();
        currentSlides.forEach((slide) => {
            galleryThumbsSlides.push(`<li class="swiper-slide">
                <img src="${slide.image}" srcset="${slide.image_2x} 2x">
            </li>`);
            galleryMainSlides.push(`<li class="swiper-slide product__image">
                <img src="${slide.image}" srcset="${slide.image_2x} 2x">
            </li>`);
        });
        galleryThumbs.appendSlide(galleryThumbsSlides);
        galleryMain.appendSlide(galleryMainSlides);
        document.querySelectorAll('.product__image').forEach((img) => {
            img.addEventListener('click', openFullScreen);
        });
    };

    const appendSlidesFullScreen = () => {
        const currentVariant = getCurrentVariant();
        const currentSlides = productImages.filter((img) => {
            return !currentVariant || !img.id || img.id === currentVariant;
        });
        galleryThumbs.removeAllSlides();
        galleryMain.removeAllSlides();
        currentSlides.forEach((slide) => {
            galleryThumbs.appendSlide(`<li class="swiper-slide">
                <img src="${slide.image_large}">
            </li>`);
            galleryMain.appendSlide(`<li class="swiper-slide product__image">
                <img src="${slide.image_large}">
            </li>`);
        });
        document.querySelectorAll('.product__image').forEach((img) => {
            img.removeEventListener('click', openFullScreen);
        });
    };

    const openFullScreen = () => {
        if (window.innerWidth <= MOBILE_SCREEN_WIDTH) {
            return;
        }
        const currentSlide = galleryMain.activeIndex;
        productImagesContainer.classList.add('product__images--fullscreen');
        overlay.classList.add('product__images-overlay--visible');
        galleryThumbs.changeDirection('horizontal');
        galleryMain.allowTouchMove = true;
        galleryMain.changeDirection('horizontal');
        galleryThumbs.updateSize();
        galleryMain.updateSize();
        appendSlidesFullScreen();
        galleryMain.slideTo(currentSlide, 0);
        galleryThumbs.slideTo(currentSlide, 0);
        overlay.addEventListener('click', closeFullScreen);
        closeFullScreenBtn.addEventListener('click', closeFullScreen);
    };

    const closeFullScreen = () => {
        const currentSlide = galleryMain.activeIndex;
        productImagesContainer.classList.remove('product__images--fullscreen');
        overlay.classList.remove('product__images-overlay--visible');
        galleryThumbs.changeDirection(getDirection());
        galleryMain.allowTouchMove = window.innerWidth <= MOBILE_SCREEN_WIDTH;
        galleryMain.changeDirection(getDirection());
        galleryThumbs.updateSize();
        galleryMain.updateSize();
        appendSlides();
        galleryMain.slideTo(currentSlide, 0);
        galleryThumbs.slideTo(currentSlide, 0);
        overlay.removeEventListener('click', closeFullScreen);
        closeFullScreenBtn.removeEventListener('click', closeFullScreen);
    };

    appendSlides();

    variants.forEach((variant) => {
        variant.addEventListener('change', appendSlides);
    });

    window.onresize = debounce(closeFullScreen, 500);
};

document.addEventListener('turbolinks:load', initProductPageSwiper);
//document.addEventListener('turbolinks:load', initMainPageSwiper); //slider for products on main page. temporary disabled
