const initProductPropertiesToggle = () => {
    const productPropertiesTemplate = document.querySelector('#product-properties-json');
    const productPropertiesContainer = document.querySelector('#product-properties');

    if (!productPropertiesTemplate || !productPropertiesTemplate.content || !productPropertiesContainer) {
        return;
    }

    const productProperties = JSON.parse(productPropertiesTemplate.content.textContent);
    if (!productProperties || !productProperties.length) {
        return;
    }

    const variants = document.querySelectorAll('[name="variant_id"]');

    const getCurrentVariant = () => {
        let currentVariant = null;
        variants.forEach((variant) => {
            if (variant.checked) {
                currentVariant = parseInt(variant.value);
            }
        });
        return currentVariant;
    };

    const appendProperties = () => {
        const currentVariant = getCurrentVariant();
        const currentProperties = productProperties.filter((prop) => {
            return !currentVariant || !prop.id || prop.id === currentVariant;
        });

        const temporaryContainer = document.createElement('div');
        productPropertiesContainer.innerHTML = '';
        currentProperties.forEach((prop) => {
            const propsItem = document.createElement('li');
            propsItem.classList.add('product__prop');

            const propsTitle = document.createElement('span');
            propsTitle.classList.add('product__prop-title');
            propsTitle.textContent = prop.presentation;

            const propsValue = document.createElement('span');
            propsValue.classList.add('product__prop-value');
            propsValue.textContent = prop.value;

            propsItem.append(propsTitle, propsValue);
            temporaryContainer.append(propsItem);
        });
        productPropertiesContainer.innerHTML = temporaryContainer.innerHTML;
        temporaryContainer.remove();
    };

    appendProperties();

    variants.forEach((variant) => {
        variant.addEventListener('change', appendProperties);
    });
};

document.addEventListener('turbolinks:load', initProductPropertiesToggle);
